<template>
    <div>
        <h2 class="font-weight-light mb-2">
            Two-Factor Authentication
        </h2>

        <enable-two-factor-auth v-if="!isEnabled" />
        <disable-two-factor-auth v-if="isEnabled" />
    </div>
</template>

<script>
import EventBus from '@eventBus'
import EnableTwoFactorAuth from './enable2fa'
import DisableTwoFactorAuth from './disable2fa'
import { ssoGet, handleNetworkErrors, handleNetworkUnauthorized } from '@src/utils/helpers'

export default {
    name: 'TwoFactorAuth',
    components: {
        EnableTwoFactorAuth,
        DisableTwoFactorAuth
    },
    data: () => ({
        isEnabled: 0
    }),
    created () {
        this.isTfaEnabled()
        EventBus.$on('twoFactorAuth:success', () => {
            this.isTfaEnabled()
        })
    },
    methods: {
        isTfaEnabled () {
            ssoGet('two-factor')
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then((data) => {
                    this.isEnabled = data.isEnabled
                })
                .catch(handleNetworkUnauthorized)
        }
    }
}
</script>
