<template>
    <v-app>
        <header-section :user-email="user.email" />
        <v-main>
            <router-view />
        </v-main>
        <div class="pt-14">
            <footer-section />
        </div>
        <snack-bar />
    </v-app>
</template>

<script>
import { store } from '@src/store/index'
import HeaderSection from './components/shared/Header'
import FooterSection from './components/shared/Footer'
import SnackBar from './components/shared/SnackBar'

export default {
    name: 'App',
    components: {
        HeaderSection,
        FooterSection,
        SnackBar
    },
    computed: {
        user: () => ({ ...store.user })
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
    font-family: 'Montserrat', sans-serif, monospace;
    margin: 0px;
    padding: 0px;
}

*, :after, :before {
    box-sizing: inherit;
}

:after, :before {
    text-decoration: inherit;
    vertical-align: inherit;
}

html {
    font-size: 14px;
    overflow-y: auto !important; /* overide vuetify default behavior */
}

a {
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family: bebas-neue, sans-serif, monospace;
}

.not-allowed{
    cursor: not-allowed;
}

.page-title {
    font-family: bebas-neue, sans-serif, monospace;
    font-size: 42px;
    color: #000;
    height: 84px;
    font-weight: 200;
    padding: 10px 0;
}
.over {
    display: flex;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(255,255,255,0.6);
}
</style>
