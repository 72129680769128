<template>
    <v-container>
        <v-row>
            <v-col cols="12" wrap style="position:relative">
                <h2 class="font-weight-light">
                    Personal Access Tokens
                </h2>

                <v-data-table
                    dense
                    :search="search"
                    :headers="headers"
                    :items="tokens || []"
                    :single-select="true"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    item-key="updatedAt"
                    class="elevation-1"
                >
                    <template v-slot:[`top`]>
                        <div class="d-flex align-center px-2">
                            <v-text-field
                                v-model="search"
                                label="Search"
                                class="mx-2"
                                append-icon="mdi-magnify"
                            />

                            <div class="mx-2">
                                <manage-token />
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.activated`]="{ item }">
                        <v-switch
                            v-model="item.activated"
                            dense
                            inset
                            hide-details
                            class="ma-0"
                            color="success"
                            @change="updateToken($event, item)"
                        />
                    </template>

                    <template v-slot:[`item.updatedAt`]="{ item }">
                        {{ item.updatedAt | localDate }}
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex justify-end">
                            <manage-token type="edit" btn="icon" :inittoken="item" />
                            <delete-token :token="item" />
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import EventBus from '@eventBus'
import { store, mutations } from '@src/store/index'
import { localDate } from '@src/utils/filters'
import { ssoGet, ssoManage, handleNetworkErrors, handleNetworkUnauthorized } from '@src/utils/helpers'
import ManageToken from './pats/manageToken'
import DeleteToken from './pats/deleteToken'

export default {
    name: 'Tokens',
    filters: {
        localDate
    },
    components: {
        ManageToken,
        DeleteToken
    },
    data: () => ({
        loading: false,
        search: '',
        headers: [
            { text: 'Activated', value: 'activated', width: '10%', sortable: false },
            { text: 'Note', value: 'note' },
            { text: 'Date', value: 'updatedAt' },
            { text: 'Actions', value: 'actions', align: 'right', sortable: false }
        ]
    }),
    computed: {
        tokens: () => ([...store.tokens])
    },
    created () {
        this.getUserTokens()
        EventBus.$on('token:success', () => {
            this.getUserTokens()
        })
    },
    methods: {
        getUserTokens () {
            this.loading = true

            ssoGet('personal-tokens')
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then((data) => mutations.set('tokens', data))
                .catch(handleNetworkUnauthorized)
                .finally(() => {
                    this.loading = false
                })
        },
        updateToken (data, item) {
            item.activated = data

            ssoManage({
                payload: item,
                type: 'PUT',
                id: item._id,
                endpoint: 'personal-tokens'
            })
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then(this.successHandler)
                .catch(this.errorHandler)
        },
        successHandler () {
            this.getUserTokens()
            EventBus.$emit('snackbar:show', {
                type: 'success',
                message: 'The Token has been updated !'
            })
        },
        errorHandler () {
            EventBus.$emit('snackbar:show', {
                type: 'error',
                message: 'The Token has NOT been updated...'
            })
        }
    }
}
</script>
