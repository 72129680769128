<template>
    <v-footer absolute padless dark>
        <v-card
            class="flex text-caption text--white"
            color="black"
            flat
            tile
        >
            <v-container class="py-2">
                <div class="d-flex flex-column flex-sm-row align-center justify-space-between">
                    <div>
                        <v-btn
                            v-for="link in links" :key="link.name"
                            class="text-capitalize"
                            target="_blank"
                            :href="link.link"
                            small
                            text
                        >
                            {{ link.name }}
                        </v-btn>
                    </div>

                    <div>
                        ©{{ thisYear }} - Pycom {{ version }} | All Rights Reserved
                    </div>
                </div>
            </v-container>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    name: 'Footer',
    data () {
        return {
            thisYear: new Date().getFullYear(),
            version: process.env.VUE_APP_PACKAGE_VERSION,
            links: [{
                name: 'Terms and conditions',
                link: process.env.VUE_APP_TERMS_URL
            }, {
                name: 'Legal',
                link: process.env.VUE_APP_PRIVACY_URL
            }]
        }
    }
}
</script>
