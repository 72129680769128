var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","wrap":""}},[_c('h2',{staticClass:"font-weight-light"},[_vm._v(" Sessions ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.reversedTokens,"footer-props":_vm.footer,"show-select":"","item-key":"_id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center px-2"},[_c('v-text-field',{staticClass:"mx-2",attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"id":"deleteSessions-btn","small":"","color":"error","disabled":!_vm.hasOneSelected},on:{"click":_vm.removeSelectedTokens}},[_vm._v(" delete sessions ")])],1)]},proxy:true},{key:"item.env.ip",fn:function(ref){
var item = ref.item;
return [_c('small',{staticClass:"mr-2"},[(item.env.ipLookup)?_c('flag',{attrs:{"iso":_vm._f("lowercase")(item.env.ipLookup.country),"squared":false}}):_vm._e()],1),_c('span',[_vm._v(" "+_vm._s(item.env.ip)+" ")])]}},{key:"item.serviceName",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{style:({ color: item.color }),attrs:{"small":""}},[_vm._v(" mdi-circle ")]),(item.current)?_c('span',[_vm._v(" current ")]):_vm._e(),(!item.current)?_c('span',[_vm._v(" "+_vm._s(item.serviceName || item.clientId)+" ")]):_vm._e()]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDate")(item.updatedAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.removeTokenById(item._id)}}},[_vm._v(" mdi-trash-can-outline ")])]}}],null,true),model:{value:(_vm.selectedTokens),callback:function ($$v) {_vm.selectedTokens=$$v},expression:"selectedTokens"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","wrap":""}},[_c('h2',{staticClass:"font-weight-light error--text"},[_vm._v(" Delete Account ")]),_c('p',{staticClass:"caption"},[_vm._v(" Your account will be immediately deactivated. Please be certain. ")]),_c('v-btn',{attrs:{"id":"deleteAccount-btn","small":"","outlined":"","color":"error"},on:{"click":function($event){$event.stopPropagation();_vm.dialogDeleteAccount = true}}},[_vm._v(" Delete my account ")])],1)],1),_c('DeleteAccountForm',{attrs:{"show":_vm.dialogDeleteAccount},on:{"update-show":_vm.update}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }