<template>
    <v-form
        ref="addItem"
        v-model="valid"
        @submit.prevent
    >
        <small>{{ label }}</small>
        <div
            v-for="(item, key) in items"
            :key="key"
            class="pt-2"
        >
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-chip
                        small
                        close
                        v-bind="attrs"
                        v-on="on"
                        @click:close="removeItem(key)"
                    >
                        <span class="text-truncate">
                            {{ item }}
                        </span>
                    </v-chip>
                </template>
                <span>
                    {{ item }}
                </span>
            </v-tooltip>
        </div>

        <v-text-field
            v-model="item"
            v-click-outside="resetState"
            dense
            outlined
            rounded
            class="mt-4"
            :rules="rules"
        >
            <template v-slot:append-outer>
                <v-btn
                    fab
                    small
                    depressed
                    class="success"
                    style="top: -8px"
                    :disabled="!valid"
                    @click="addItem"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
        </v-text-field>
    </v-form>
</template>

<script>
export default {
    name: 'ItemList',
    props: {
        label: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: () => ([])
        },
        rules: {
            type: Array,
            default: () => ([(v) => !!v || 'Field is required'])
        }
    },
    data: () => ({
        item: '',
        valid: false
    }),
    methods: {
        addItem () {
            this.items.push(this.item)
            this.resetState()
        },
        removeItem (i) {
            this.items.splice(i, 1)
        },
        resetState () {
            this.item = ''
            this.valid = false
            this.$refs.addItem.resetValidation()
        }
    }
}
</script>
