<template>
    <div>
        <v-alert
            icon="mdi-shield-lock-outline"
            text
            dense
            border="left"
            class="my-4"
        >
            <div class="mb-1">
                Two factor authentication is not enabled yet.
            </div>
            <div>
                Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in.
            </div>
        </v-alert>

        <v-dialog
            v-model="dialog"
            width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    depressed
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                >
                    Enable two-factor authentication
                </v-btn>
            </template>

            <v-card>
                <v-form
                    ref="form"
                    v-model="valid"
                    @submit.prevent="verifyTwoFactor"
                >
                    <v-card-title class="headline primary white--text">
                        <span>Set up using an app</span>
                        <v-spacer />
                        <v-btn icon dark @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <h2>
                                        1. Scan this barcode with your app.
                                    </h2>

                                    <div v-if="otp" class="my-2 d-flex flex-column justify-space-between align-center">
                                        <v-img
                                            height="250"
                                            width="250"
                                            :src="otp.otpQr"
                                        />
                                        <p>
                                            Scan the image above with the two-factor authentication app on your phone.
                                            If you can’t use a barcode, enter the following code instead.
                                            <code>{{ otp.otpKey }}</code>
                                        </p>
                                    </div>

                                    <h2>
                                        2. Enter the six-digit code from the application
                                    </h2>

                                    <div class="mt-2">
                                        <v-text-field
                                            v-model="sixDigitCode"
                                            dense
                                            outlined
                                            label="Six-digit code"
                                            name="sixDigitCode"
                                            value
                                            :rules="sixDigitRule"
                                            required
                                        />
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-divider />

                    <v-card-actions>
                        <v-spacer />

                        <v-btn
                            type="submit"
                            color="success"
                            :disabled="isLoading || !valid"
                        >
                            <span v-if="!isLoading">
                                Validate
                            </span>
                            <v-icon v-if="isLoading">
                                mdi-loading mdi-spin
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import EventBus from '@eventBus'
import { ssoManage, handleNetworkErrors, handleNetworkUnauthorized } from '@src/utils/helpers'

export default {
    name: 'EnableTwoFactorAuth',
    props: {},
    data: () => ({
        dialog: false,
        valid: false,
        isLoading: false,
        otp: {},
        sixDigitCode: null,
        sixDigitRule: [
            (v) => !!v || 'This field is required',
            (v) => /^[0-9]{6}$/.test(v) || 'Must be 6 digits'
        ]
    }),
    watch: {
        dialog: function (v) {
            if (!v) {
                this.resetState()
                return
            }
            this.createTwoFactor()
        }
    },
    methods: {
        createTwoFactor () {
            ssoManage({
                type: 'POST',
                endpoint: 'two-factor'
            })
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then(this.setOtpData)
                .catch(handleNetworkUnauthorized)
        },
        verifyTwoFactor () {
            this.isLoading = true

            const payload = {
                otpToken: this.sixDigitCode,
                otpKey: this.otp.jwtKey
            }

            ssoManage({
                payload,
                type: 'PUT',
                endpoint: 'two-factor'
            })
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then(this.successHandler)
                .catch(this.errorHandler)
        },
        setOtpData ({ otpKey, otpQr }) {
            const data = JSON.parse(atob(otpKey.split('.')[1]))
            this.otp = {
                jwtKey: otpKey,
                otpKey: data.otpKey,
                otpQr
            }
        },
        successHandler (data) {
            this.resetState()
            EventBus.$emit('snackbar:show', {
                type: 'success',
                message: 'The 2FA code has been validated !'
            })
            EventBus.$emit('twoFactorAuth:success')
        },
        errorHandler () {
            this.valid = false
            this.isLoading = false
            this.sixDigitCode = null
        },
        resetState () {
            this.isLoading = false
            this.otp = {}
            this.sixDigitCode = null
            document.activeElement.blur()
            this.$refs.form.resetValidation()
        }
    }
}
</script>
