<template>
    <v-flex class="card-wrapper">
        <a :href="serviceUrl" :target="target">
            <v-card class="v-card-section" :class="{'not-allowed': commingSoonFlag}">
                <v-layout class="tag-section-wrapper">
                    <v-layout v-if="commingSoonFlag" class="tag-section text-center">
                        <span class="tag-text">soon</span>
                    </v-layout>
                </v-layout>
                <v-layout justify-center row class="pt-3">
                    <img
                        class="service-icon "
                        :src="iconLink"
                        alt="module menu"
                    >
                </v-layout>
                <v-card-title>
                    <v-layout justify-center row>
                        <h1 class="card-title"> {{ serviceName.toUpperCase() }} </h1>
                    </v-layout>
                </v-card-title>
                <v-card-text>
                    <div class="card-text">
                        {{ serviceDescription }}
                    </div>
                </v-card-text>
            </v-card>
        </a>
    </v-flex>
</template>

<script>
export default {
    name: 'ServiceCard',
    props: {
        commingSoonFlag: {
            type: Boolean,
            default: false
        },
        iconLink: {
            type: String,
            required: true
        },
        serviceName: {
            type: String,
            required: true
        },
        serviceDescription: {
            type: String,
            required: true
        },
        serviceUrl: {
            type: String,
            required: false,
            default: undefined
        },
        target: {
            type: String,
            default: '_self'
        }
    },
    data () {
        return {}
    },
    mounted () {},
    methods: {}
}
</script>

<style scoped>
    .v-card-section{
        height: 200px;
    }

    .service-icon {
        height: 52px;
    }

    .tag-section-wrapper{
        width: 100%;
        position: relative;
        margin-bottom: 15px;
    }

    .tag-section {
        padding: 1px 8px 2px 8px;
        border-radius: 16px;
        background-color: #00cc96;
        position: absolute;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1px;
        right: 5px;
        top: 5px;
        color: #FFF;
    }

    .tag-text{
        display: inline-block;
        text-align: center;
        width: 100%;
    }

    .card-title {
        height: 16px;
        font-family: bebas-neue, sans-serif, monospace;
        font-size: 23px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.91;
        letter-spacing: normal;
        color: #000;
    }

    .card-text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #757575;
    }
</style>
