<template>
    <v-container class="fill-height" fluid>
        <v-col align="center" justify="center">
            <v-flex md12 class="mb-4">
                <h1>There is nothing here</h1>
            </v-flex>
            <v-flex xs12 sm6 lg4 class="py-4">
                <img class="404" :src="require('@sharedAssets/pychip_404.svg')" alt="Pycom - 404">
            </v-flex>
            <v-flex md12 class="mt-4">
                <v-btn href="/" small color="success">
                    <b>Go to home</b>
                </v-btn>
            </v-flex>
        </v-col>
    </v-container>
</template>

<script>
export default {
    name: 'NotFoundComponent'
}
</script>

<style scoped>
h1 {
    color: #898989
}
</style>
