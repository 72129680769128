<template>
    <v-row align="center" justify="center">
        <v-progress-circular
            indeterminate
            color="purple"
        />
    </v-row>
</template>

<script>
export default {
    name: 'Loader'
}
</script>
