import Vue from 'vue'
import Router from 'vue-router'

import EventBus from '@eventBus'
import { mutations, store } from '@src/store/index'
import { ssoGet, ssoManage, ssoRedirect, handleNetworkErrors } from '@src/utils/helpers'
import Home from '../components/Home'
import Account from '../components/Account'
import AccountDetails from '../components/account/AccountDetails'
import Addresses from '../components/account/Addresses'
import Orders from '../components/account/Orders'
import Security from '../components/account/Security'
import Tokens from '../components/account/Tokens'
import Advanced from '../components/account/Advanced'
import NotFound from '../components/NotFound'

Vue.use(Router)

// Define routes
const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
}, {
    path: '/account',
    component: Account,
    meta: { requiresAuth: true },
    children: [{
        path: '',
        component: AccountDetails
    }, {
        path: 'addresses',
        component: Addresses
    }, {
        path: 'orders',
        component: Orders
    }, {
        path: 'security',
        component: Security
    }, {
        path: 'tokens',
        component: Tokens,
        meta: { disabled: !(process.env.VUE_APP_ENABLE_PAT === 'true') }
    }, {
        path: 'advanced',
        component: Advanced
    }]
}, {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: { requiresAuth: true }
}]

// Define router
const router = new Router({
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    mode: 'history',
    routes
})

// Route hook
router.beforeEach((to, from, next) => {
    // Check is route is disabled
    if (to.matched.some(record => record.meta.disabled)) {
        return next({ name: 'NotFound' })
    }

    // Check if user is authorized
    if (to.matched.some(record => record.meta.requiresAuth)) {
        mutations.set('isAuthorized', false)
        mutations.set('isAdmin', false)

        ssoManage({
            type: 'POST',
            endpoint: 'api/auth/validate'
        })
            .then(handleNetworkErrors)
            .then((res) => res.json())
            .then((data) => {
                mutations.set('isAuthorized', true)
                mutations.set('isAdmin', data.user.roles.includes('admin'))
                mutations.set('user', data.user)

                if (!data.user.verified && (from.path !== '/' || to.path !== '/')) {
                    router.push('/').catch(_ => {})
                }

                if (to.matched.some(record => record.meta.requiresAdmin) && !store.isAdmin) {
                    return next({ name: 'NotFound' })
                }

                return next()
            })
            .catch(async (err) => {
                const error = await err.json()
                // If invalid MFA return to login page to show the 2FA field
                if (error.message && error.message.validMFA === false) {
                    return ssoRedirect('login')
                }

                // Check with refresh token
                ssoManage({
                    type: 'POST',
                    endpoint: 'oauth/token'
                })
                    .then(handleNetworkErrors)
                    .then(() => ssoGet('oauth/userinfo'))
                    .then((res) => res.json())
                    .then((user) => {
                        EventBus.$emit('snackbar:hide')
                        mutations.set('isAuthorized', true)
                        mutations.set('isAdmin', user.roles.includes('admin'))
                        mutations.set('user', user)
                        if (to.matched.some(record => record.meta.requiresAdmin) && !store.isAdmin) {
                            return next({ name: 'NotFound' })
                        }
                        return next()
                    })
                    .catch((err) => {
                        if (err.status === 400 || err.status === 401) {
                            return ssoRedirect('logout')
                        }
                    })
            })
    }
})

export default router
