<template>
    <v-dialog v-model="show" persistent max-width="400">
        <v-card id="accountDeletion-dialog" relative>
            <v-toolbar dense flat elevation="1" class="mb-4">
                <v-toolbar-title class="subtitle-1">
                    Are you sure you want to do this?
                </v-toolbar-title>

                <v-spacer />

                <v-btn icon @click.prevent="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <v-alert
                    dense
                    type="error"
                    outlined
                    class="caption error lighten-5"
                >
                    This is extremely important.
                </v-alert>

                <p>
                    Your account will be <strong>immediately deactivated.</strong>
                    After 30 days, your account and all you data will be deleted.
                    If you'd like to reactivate your account before that happens, just log in to Pycom.
                </p>

                <div class="mb-4" style="border-top: solid 1px rgba(0,0,0,0.2)" />

                <v-form
                    id="delete-account"
                    ref="form"
                    v-model="valid"
                    @submit.prevent="deleteAccount"
                >
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="form.username"
                                label="Type your email"
                                :rules="emailRules"
                                dense
                                required
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="form.password"
                                label="Confirm your password"
                                :type="showPwd ? 'text' : 'password'"
                                :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="passwordRules"
                                dense
                                required
                                @click:append="showPwd = !showPwd"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="form.verify"
                                label="To verify, type DELETE"
                                :rules="verifyRules"
                                dense
                                required
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-btn
                                :disabled="!valid"
                                type="submit"
                                color="error"
                                block
                                small
                            >
                                Delete this account
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import EventBus from '@eventBus'
import { ssoManage, ssoRedirect } from '@src/utils/helpers'

export default {
    name: 'DeleteAccountForm',
    components: {},
    props: {
        show: { type: Boolean }
    },
    data: () => ({
        valid: false,
        showPwd: false,
        form: {
            username: '',
            verify: '',
            password: ''
        },
        emailRules: [
            (v) => !!v || 'E-mail is required',
            (v) => /^\w+([.\-+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(v) || 'E-mail must be valid'
        ],
        verifyRules: [
            (v) => !!v || 'This field is required',
            (v) => v === 'DELETE' || 'Should be DELETE'
        ],
        passwordRules: [
            (v) => !!v || 'Password is required'
        ]
    }),
    methods: {
        deleteAccount () {
            if (this.$refs.form.validate()) {
                ssoManage({
                    payload: this.form,
                    type: 'DELETE',
                    endpoint: 'oauth/userinfo/delete-account'
                })
                    .then((res) => {
                        if (res.status === 200) {
                            ssoRedirect('logout')
                        } else {
                            EventBus.$emit('snackbar:show', {
                                type: 'error',
                                message: 'Your account cannot be deleted'
                            })
                        }
                    })
            }
        },
        close () {
            this.form = {}
            this.$refs.form.resetValidation()
            this.$emit('update-show', false)
        }
    }
}
</script>
