import Vue from 'vue'
import App from './App.vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'
import router from './router'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import FlagIcon from 'vue-flag-icon'

if (process.env.VUE_APP_ENABLE_SENTRY === 'true') {
    Sentry.init({
        dsn: 'https://d8e07e8f87a34fcb8282697379abe81e@o167823.ingest.sentry.io/5453874',
        release: `portal@${process.env.VUE_APP_PACKAGE_VERSION}`,
        integrations: [
            new VueIntegration({
                Vue,
                tracing: true,
                logErrors: true
            }),
            new Integrations.BrowserTracing()
        ],
        tracesSampleRate: 0.1
    })
}

Vue.use(FlagIcon)

if (process.env.VUE_APP_DEBUG === 'true') {
    localStorage.setItem('debug', 'app*')
} else {
    localStorage.removeItem('debug')
}

Vue.config.productionTip = false

new Vue({
    vuetify,
    render: h => h(App),
    router
}).$mount('#app')
