<template>
    <v-dialog
        v-model="dialog"
        width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                color="error"
                class="ml-1"
                v-bind="attrs"
                v-on="on"
            >
                mdi-trash-can-outline
            </v-icon>
        </template>

        <v-card>
            <v-form
                ref="form"
                @submit.prevent="deleteAddress"
            >
                <v-card-title class="headline primary white--text">
                    <span>Delete</span>
                    <v-spacer />
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <p>
                                    You are about to delete the following address:
                                </p>
                                <p class="title">
                                    {{ address.title }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        outlined
                        :disabled="isLoading"
                        type="submit"
                        color="error"
                    >
                        <span v-if="!isLoading">
                            Delete
                        </span>
                        <v-icon v-if="isLoading">
                            mdi-loading mdi-spin
                        </v-icon>
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import EventBus from '@eventBus'
import { ssoManage, handleNetworkErrors } from '@src/utils/helpers'

export default {
    name: 'DeleteAddress',
    props: {
        address: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        dialog: false,
        isLoading: false
    }),
    methods: {
        deleteAddress () {
            this.isLoading = true

            ssoManage({
                type: 'DELETE',
                id: this.address._id,
                endpoint: 'addresses'
            })
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then(this.successHandler)
                .catch(this.resetState)
        },
        resetState () {
            this.dialog = false
            this.isLoading = false
        },
        successHandler (data) {
            EventBus.$emit('address:success')
            EventBus.$emit('snackbar:show', {
                type: 'success',
                message: 'The address has been deleted !'
            })
            this.resetState()
        }
    }
}
</script>
