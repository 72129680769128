<template>
    <v-dialog
        v-model="dialog"
        style="max-width: 100px"
        @click:outside="resetState"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="type === 'create'"
                small
                class="success"
                v-bind="attrs"
                v-on="on"
            >
                {{ cta }}
            </v-btn>

            <v-icon
                v-if="type === 'edit' && btn === 'icon'"
                small
                class="ml-1"
                v-bind="attrs"
                v-on="on"
            >
                mdi-pencil
            </v-icon>

            <v-btn
                v-if="type === 'edit' && btn === 'text'"
                small
                class="success"
                v-bind="attrs"
                v-on="on"
            >
                Edit token
            </v-btn>
        </template>

        <v-card>
            <v-form
                ref="form"
                v-model="valid"
                @submit.prevent="manageToken"
            >
                <v-card-title class="headline primary white--text">
                    <span>{{ cta }}: {{ token.note }}</span>
                    <v-spacer />
                    <v-btn icon dark @click="resetState">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <!-- GENERATED -->
                <v-card-text>
                    <v-container v-if="generatedKey && generatedKey.key">
                        <v-row>
                            <v-col cols="12">
                                <div class="pb-4">
                                    <v-alert
                                        dense
                                        border="left"
                                        type="warning"
                                    >
                                        Make sure to copy your new personal access token now. You won’t be able to see it again!
                                    </v-alert>
                                </div>

                                <div>
                                    <v-text-field
                                        outlined
                                        label="Token"
                                        name="token"
                                        :value="generatedKey.key"
                                        prepend-icon="mdi-check"
                                        append-outer-icon="mdi-content-copy"
                                        @click:append-outer="copyToken"
                                    />
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>

                    <!-- FORM -->
                    <v-container v-if="!generatedKey">
                        <v-row>
                            <v-col cols="12">
                                <v-alert
                                    icon="mdi-shield-lock-outline"
                                    text
                                    dense
                                    border="left"
                                >
                                    Personal Access Token are like passwords, make sure to securely store it.
                                </v-alert>
                            </v-col>

                            <v-col cols="12">
                                <h2>
                                    Note
                                </h2>

                                <div class="py-1">
                                    What’s this Token for?
                                </div>

                                <v-text-field
                                    v-model="token.note"
                                    name="note"
                                    label="Note*"
                                    :rules="requiredRule"
                                    required
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <h2>
                                    Ips Allowed
                                </h2>

                                <item-list :items="token.ipsAllowed" :rules="ipsRule" />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <h2>
                                    Select Scopes
                                </h2>

                                <div class="py-1">
                                    Scopes define the access for personal tokens.
                                </div>

                                <v-list
                                    v-for="(caps, index) in capabilityNodes"
                                    :key="index"
                                >
                                    <v-list-item
                                        v-for="(val, key) in caps"
                                        :key="key"
                                        class="caps-item"
                                        :class="key"
                                    >
                                        <v-checkbox
                                            v-model="token.capabilities"
                                            hide-details
                                            :value="key"
                                            @change="onChange($event, key, index)"
                                        >
                                            <template v-slot:label>
                                                <span>{{ key }}</span>
                                                <small class="ml-2 caption font-italic">
                                                    ({{ val }})
                                                </small>
                                            </template>
                                        </v-checkbox>
                                    </v-list-item>

                                    <v-divider
                                        v-if="index < capabilityNodes.length - 1"
                                        class="mt-4"
                                    />
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        v-if="!generatedKey"
                        :disabled="!valid || isLoading"
                        type="submit"
                        color="success"
                    >
                        <span v-if="!isLoading">
                            {{ token._id ? "Update Token" : "Generate Token" }}
                        </span>
                        <v-icon v-if="isLoading">
                            mdi-loading mdi-spin
                        </v-icon>
                    </v-btn>

                    <button
                        v-if="generatedKey && generatedKey.key"
                        type="button"
                        class="v-btn"
                        color="success"
                        @click="resetState"
                    >
                        <span>
                            Close
                        </span>
                    </button>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import EventBus from '@eventBus'
import ItemList from '../../shared/ItemList'
import { ssoManage, handleNetworkErrors } from '@src/utils/helpers'

export default {
    name: 'ManageToken',
    components: {
        ItemList
    },
    props: {
        cta: {
            type: String,
            default: 'Add New Token'
        },
        type: {
            type: String,
            default: 'create'
        },
        btn: {
            type: String,
            default: 'text'
        },
        inittoken: {
            type: Object,
            default: () => ({
                capabilities: [],
                ipsAllowed: []
            })
        }
    },
    data () {
        return {
            dialog: false,
            isLoading: false,
            lazy: true,
            valid: false,
            token: { ...this.inittoken },
            // You have to set 'write:' in top of each block (master node)
            capabilityNodes: [{
                'write:user': 'Read/Write the user data',
                'read:user': 'Read the user data'
            }, {
                'write:networkSettings': 'Read/write the user network settings',
                'read:networkSettings': 'Read the user network settings'
            }, {
                'write:devices': 'Create/Manage all the devices',
                'read:devices': 'Read all the devices'
            },
            {
                'write:releases': 'Create/Delete releases',
                'read:releases': 'Read releases data'
            }, {
                deployment: 'Deploy code to device'
            }, {
                'read:messages': 'Read messages for device'
            }],
            generatedKey: null,
            requiredRule: [
                (v) => !!v || 'Field is required'
            ],
            ipsRule: [
                (v) => /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(v) || 'Should be a valid IP'
            ]
        }
    },
    methods: {
        copyToken () {
            try {
                const tokenEl = document.getElementsByName('token')[0]
                tokenEl.select()
                document.execCommand('copy')
                EventBus.$emit('snackbar:show', {
                    type: 'success',
                    message: 'Token as been copied'
                })
            } catch (error) {
                EventBus.$emit('snackbar:show', {
                    type: 'error',
                    message: 'Token as not been copied'
                })
            }
        },
        manageToken (e) {
            this.isLoading = true

            // Build payload
            const payload = {
                _id: this.token._id || false,
                ...this.token
            }

            // Request
            ssoManage({
                payload,
                type: !payload._id ? 'POST' : 'PUT',
                id: payload._id,
                endpoint: 'personal-tokens'
            })
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then(this.successHandler)
                .catch(this.errorHandler)
        },
        resetState () {
            this.token = this.type === 'edit'
                ? this.generatedKey || { ...this.inittoken }
                : { capabilities: [] }
            this.generatedKey = null
            this.isLoading = false
            this.dialog = false
            document.activeElement.blur()
            this.$refs.form.resetValidation()
        },
        successHandler (data) {
            this.generatedKey = data || null
            if (this.token._id) {
                this.resetState()
            }
            EventBus.$emit('snackbar:show', {
                type: 'success',
                message: 'The Token has been updated !'
            })
            EventBus.$emit('token:success')
        },
        onChange (e, k, i) {
            // Only if current value is an array
            if (!Array.isArray(e)) return
            const node = Object.keys(this.capabilityNodes[i])
            // if selected, master node and start with 'write:', select all subnodes
            if (e.includes(k) && node.indexOf(k) === 0 && k.startsWith('write:')) {
                this.token.capabilities = [...new Set([...node, ...e])]
            }
        },
        arrIsEqual (a, b) {
            return [...Array(Math.max(a.length, b.length))]
                .every((_, i) => a[i] === b[i])
        },
        async errorHandler (data) {
            const error = await data.json()
            const message = 'Undefined Error'
            switch (error.error) {
                default:
                    this.resetState()
                    break
            }
            EventBus.$emit('snackbar:show', {
                type: 'error',
                message: message
            })
        }
    }
}
</script>

<style scoped>
    .caps-item {
        min-height: 0;
        margin: 0;
        padding: 0;
    }

    .v-input--selection-controls {
        margin-top: 0;
    }
</style>
