<template>
    <v-dialog
        v-model="dialog"
        style="max-width: 100px"
        @click:outside="resetState"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="type === 'create'"
                small
                class="success"
                v-bind="attrs"
                v-on="on"
            >
                {{ cta }}
            </v-btn>

            <v-icon
                v-if="type === 'edit' && btn === 'icon'"
                small
                class="ml-1"
                v-bind="attrs"
                v-on="on"
            >
                mdi-pencil
            </v-icon>

            <v-btn
                v-if="type === 'edit' && btn === 'text'"
                small
                class="success"
                v-bind="attrs"
                v-on="on"
            >
                Edit address
            </v-btn>
        </template>

        <v-card>
            <v-form
                ref="form"
                v-model="valid"
                @submit.prevent="manageAddress"
            >
                <v-card-title class="headline primary white--text">
                    <span>{{ cta }}: {{ address.title }}</span>
                    <v-spacer />
                    <v-btn icon dark @click="resetState">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col sm="6" cols="12" class="py-0">
                                <v-text-field
                                    v-model="address.title"
                                    name="title"
                                    label="Title*"
                                    :rules="requiredRule"
                                    :error-messages="titleUnique"
                                    required
                                    @input="cleanError"
                                />
                            </v-col>

                            <v-col sm="6" cols="12" class="py-0">
                                <v-select
                                    v-model="selectedTypes"
                                    label="Type"
                                    name="types"
                                    :items="types"
                                    multiple
                                >
                                    <template v-slot:item="{ item, attrs, on }">
                                        <v-list-item #default="{ active }" v-bind="attrs" v-on="on">
                                            <v-checkbox :input-value="active" />
                                            <div>
                                                {{ item }}
                                                <div
                                                    v-if="initbsaddresses[item] && initbsaddresses[item]._id !== address._id"
                                                    class="caption font-weight-light"
                                                >
                                                    Already used by: <b>{{ initbsaddresses[item].title }}</b>
                                                </div>
                                            </div>
                                        </v-list-item>
                                    </template>
                                </v-select>
                            </v-col>

                            <v-col sm="6" cols="12" class="py-0">
                                <v-text-field
                                    v-model="address.firstname"
                                    label="Firstname*"
                                    name="firstname"
                                    :rules="requiredRule"
                                    required
                                />
                            </v-col>

                            <v-col sm="6" cols="12" class="py-0">
                                <v-text-field
                                    v-model="address.lastname"
                                    label="Lastname*"
                                    name="lastname"
                                    :rules="requiredRule"
                                    required
                                />
                            </v-col>

                            <v-col sm="6" cols="12" class="py-0">
                                <v-text-field
                                    v-model="address.email"
                                    label="Email*"
                                    name="email"
                                    :rules="emailRules"
                                    required
                                />
                            </v-col>

                            <v-col sm="6" cols="12" class="py-0">
                                <v-text-field
                                    v-model="address.phone"
                                    name="phone"
                                    label="Phone"
                                    required
                                />
                            </v-col>

                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="address.company"
                                    name="company"
                                    label="Company"
                                />
                            </v-col>

                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="address.address1"
                                    label="Address1*"
                                    name="address1"
                                    :rules="requiredRule"
                                    required
                                />
                            </v-col>

                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="address.address2"
                                    label="Address2"
                                    name="address2"
                                />
                            </v-col>

                            <v-col sm="6" cols="12" class="py-0">
                                <v-text-field
                                    v-model="address.city"
                                    label="City*"
                                    name="city"
                                    :rules="requiredRule"
                                    required
                                />
                            </v-col>

                            <v-col sm="6" cols="12" class="py-0">
                                <v-text-field
                                    v-model="address.postcode"
                                    label="Postcode*"
                                    name="postcode"
                                    :rules="requiredRule"
                                    required
                                />
                            </v-col>

                            <v-col sm="6" cols="12">
                                <v-autocomplete
                                    v-model="address.country"
                                    :items="countries"
                                    item-value="iso2"
                                    item-text="country"
                                    :rules="requiredRule"
                                    label="Country*"
                                    name="country"
                                    required
                                >
                                    <template v-slot:[`selection`]="{ item }">
                                        <small class="mr-2">
                                            <flag :iso="item.iso2" :squared="false" />
                                        </small>
                                        {{ item.country }}
                                    </template>

                                    <template v-slot:[`item`]="{ item }">
                                        <small class="mr-2">
                                            <flag :iso="item.iso2" :squared="false" />
                                        </small>
                                        {{ item.country }}
                                    </template>
                                </v-autocomplete>
                            </v-col>

                            <v-col sm="6" cols="12">
                                <v-text-field
                                    v-model="address.state"
                                    label="State"
                                    name="state"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        :disabled="!valid || isLoading"
                        type="submit"
                        color="success"
                    >
                        <span v-if="!isLoading">
                            Save
                        </span>
                        <v-icon v-if="isLoading">
                            mdi-loading mdi-spin
                        </v-icon>
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import lookup from 'country-code-lookup'
import EventBus from '@eventBus'
import { ssoManage, handleNetworkErrors } from '@src/utils/helpers'

const address = {
    title: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    postcode: '',
    country: '',
    state: ''
}

export default {
    name: 'ManageAddress',
    props: {
        cta: {
            type: String,
            default: 'Add address'
        },
        type: {
            type: String,
            default: 'create'
        },
        btn: {
            type: String,
            default: 'text'
        },
        initaddress: {
            type: Object,
            default: () => address
        },
        initbsaddresses: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            dialog: false,
            isLoading: false,
            lazy: true,
            valid: false,
            titleUnique: '',
            address: { ...this.initaddress },
            countries: lookup.countries,
            types: ['billing', 'shipping'],
            localBsAddresses: {},
            selectedTypes: [],
            requiredRule: [
                (v) => !!v || 'Field is required'
            ],
            requiredSelect: [
                (v) => !!v.length || 'Type is required'
            ],
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /^\w+([.\-+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(v) || 'E-mail must be valid'
            ]
        }
    },
    watch: {
        dialog: function (v) {
            this.selectedTypes = this.filterSelectedAddresses(this.initaddress, this.initbsaddresses)
        }
    },
    methods: {
        manageAddress (e) {
            this.isLoading = true

            // Current addresses selection
            const localBs = this.selectedTypes.reduce((acc, key) => {
                acc[key] = this.address._id ? this.address : null
                return acc
            }, {})

            // Other addresses selection
            const otherTypes = this.types.filter((type) => !this.selectedTypes.includes(type))
            const otherBs = otherTypes.reduce((acc, key) => {
                if (this.initbsaddresses[key] && this.address._id !== this.initbsaddresses[key]._id) {
                    acc[key] = this.initbsaddresses[key]
                }
                return acc
            }, {})

            // Build new bsAddresses
            this.localBsAddresses = { ...otherBs, ...localBs }

            // Build payload
            const payload = {
                address: {
                    _id: this.address._id || false,
                    ...this.address
                },
                bsAddresses: this.localBsAddresses
            }

            // Request
            ssoManage({
                payload,
                type: !payload.address._id ? 'POST' : 'PUT',
                id: payload.address._id,
                endpoint: 'addresses'
            })
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then(this.successHandler)
                .catch(this.errorHandler)
        },
        filterSelectedAddresses (address, bsAddresses) {
            const types = Object.keys(bsAddresses)
                .filter((key) => {
                    const adId = address._id
                    const bsId = bsAddresses[key]._id || bsAddresses[key]
                    return bsId && adId && bsId === adId
                })
            return types
        },
        resetState () {
            this.address = { ...this.initaddress }
            this.isLoading = false
            this.dialog = false
            this.titleUnique = ''
            document.activeElement.blur()
            this.$refs.form.resetValidation()
        },
        cleanError () {
            if (this.titleUnique) {
                this.titleUnique = ''
                this.$refs.form.validate()
            }
            this.isLoading = false
        },
        successHandler (data) {
            EventBus.$emit('address:success')
            EventBus.$emit('snackbar:show', {
                type: 'success',
                message: 'The address has been updated !'
            })
            this.resetState()
        },
        async errorHandler (data) {
            const error = await data.json()
            let message = 'Undefined Error'
            switch (error.error) {
                case 'EEXIST':
                    message = 'This address title already exists'
                    this.titleUnique = 'This title already exists'
                    this.isLoading = false
                    break
                default:
                    this.resetState()
                    break
            }
            EventBus.$emit('snackbar:show', {
                type: 'error',
                message: message
            })
        }
    }
}
</script>
