<template>
    <v-dialog
        v-model="dialog"
        width="900"
        @click:outside="resetState"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                class="order-details-cta"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon
                    small
                    class="ml-1"
                >
                    mdi-eye
                </v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="headline primary white--text">
                <span>Order #{{ order.number }}</span>
                <v-spacer />
                <v-btn icon dark @click="resetState">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" class="subtitle-1">
                            Order <b>#{{ order.number }}</b> was placed on {{ order.date_paid | localDate }} and is currently <b>{{ order.status }}</b>.
                        </v-col>

                        <v-col cols="12" class="black--text">
                            <h2>
                                Order Details
                            </h2>

                            <v-simple-table
                                dense
                                :items="order.line_items || []"
                                class="order-details-table my-2"
                            >
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            <span class="body-1 font-weight-bold black--text">
                                                Product
                                            </span>
                                        </th>
                                        <th class="text-left">
                                            <span class="body-1 font-weight-bold black--text">
                                                Total
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- Products -->
                                    <tr
                                        v-for="item in order.line_items"
                                        :key="item.id"
                                    >
                                        <td>
                                            {{ item.name }}
                                            <span class="caption font-weight-bold">
                                                x{{ item.quantity }}
                                            </span>
                                        </td>
                                        <td>
                                            {{ order.currency_symbol }}{{ item.subtotal }}
                                        </td>
                                    </tr>
                                    <!-- Subtotal -->
                                    <tr>
                                        <td>
                                            <span class="body-1 font-weight-bold">
                                                Subtotal:
                                            </span>
                                        </td>
                                        <td>
                                            <span class="body-1 font-weight-bold">
                                                {{ order.currency_symbol }}{{ (order.total - order.total_tax - order.shipping_total) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <!-- Shipping -->
                                    <tr>
                                        <td>
                                            <span class="body-1 font-weight-bold">
                                                Shipping:
                                            </span>
                                        </td>
                                        <td>
                                            <span class="body-1 font-weight-bold">
                                                {{ order.currency_symbol }}{{ order.shipping_total }}
                                            </span>
                                            via {{ order.shipping_lines | shippingMethod }}
                                        </td>
                                    </tr>
                                    <!-- VAT -->
                                    <tr>
                                        <td>
                                            <span class="body-1 font-weight-bold">
                                                VAT:
                                            </span>
                                        </td>
                                        <td>
                                            <span class="body-1 font-weight-bold">
                                                {{ order.currency_symbol }}{{ order.total_tax }}
                                            </span>
                                        </td>
                                    </tr>
                                    <!-- Payment -->
                                    <tr>
                                        <td>
                                            <span class="body-1 font-weight-bold">
                                                Payment method:
                                            </span>
                                        </td>
                                        <td>
                                            <span class="body-1 font-weight-bold">
                                                {{ order.payment_method_title }}
                                            </span>
                                        </td>
                                    </tr>
                                    <!-- Total -->
                                    <tr>
                                        <td>
                                            <span class="body-1 font-weight-bold">
                                                Total:
                                            </span>
                                        </td>
                                        <td>
                                            <span class="body-1 font-weight-bold">
                                                {{ order.currency_symbol }}{{ order.total }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>

                        <v-col md="6" cols="12" class="black--text">
                            <h2>
                                Billing Address
                            </h2>
                            <order-address class="order-details-billing" :address="order.billing" />
                        </v-col>

                        <v-col md="6" cols="12" class="black--text">
                            <h2>
                                Shipping Address
                            </h2>
                            <order-address class="order-details-shipping" :address="order.shipping" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { shippingMethod, localDate } from '@src/utils/filters'
import OrderAddress from './orderAddress'

export default {
    name: 'OrderDetails',
    components: {
        OrderAddress
    },
    filters: {
        shippingMethod,
        localDate: (v) => localDate(v, true)
    },
    props: {
        order: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            dialog: false,
            headers: [
                { text: 'Product', value: 'name', sortable: false },
                { text: 'Total', value: 'total', sortable: false }
            ]
        }
    },
    methods: {
        resetState () {
            this.dialog = false
        }
    }

}
</script>
