<template>
    <v-container fluid class="d-flex fill-height py-0">
        <loader v-if="!isAuthorized" />

        <v-layout v-if="isAuthorized" row fluid align-start align-content-start>
            <v-container>
                <v-flex md12>
                    <h1 class="page-title">
                        Our Services
                    </h1>
                </v-flex>

                <v-row>
                    <v-col v-for="service in services" :key="service.title" sm="6" md="4" xl="2" cols="12">
                        <service-card
                            :service-name="service.title"
                            :service-description="service.description"
                            :service-url="service.url"
                            :icon-link="service.icon"
                            :comming-soon-flag="service.soon"
                            :target="service.target"
                        />
                    </v-col>
                </v-row>
            </v-container>

            <verify-account
                :dialog="!user.verified"
                :email="user.email"
            />
        </v-layout>
    </v-container>
</template>

<script>
import { store } from '@src/store/index'
import ServiceCard from './shared/ServiceCard'
import VerifyAccount from './shared/VerifyAccount'
import Loader from './shared/Loader'

export default {
    name: 'Home',
    components: {
        ServiceCard,
        VerifyAccount,
        Loader
    },
    data () {
        return {
            services: [{
                title: 'Pybytes',
                description: 'Device Management Platform with OTA update for multiple devices!',
                url: process.env.VUE_APP_PYBYTES_URL,
                icon: require('@sharedAssets/module_menu.svg')
            }, {
                title: 'Pycom Web-shop',
                description: 'Plug&Play Devices, Development Boards, OEM Products and many more!',
                url: process.env.VUE_APP_PYCOM_SHOP_URL,
                icon: require('@sharedAssets/shopping-cart.svg')
            }, {
                title: 'Forum',
                description: 'The territory of enthusiasts and professionals who build IoT ecosystem',
                url: process.env.VUE_APP_PYCOM_FORUM_URL,
                icon: require('@sharedAssets/team.svg')
            }, {
                title: 'Downloads',
                description: 'All you need for IoT development',
                url: process.env.VUE_APP_DOWNLOADS_URL,
                icon: require('@sharedAssets/download.svg'),
                target: '_blank'
            }, {
                title: 'Docs',
                description: 'All you need for IoT development',
                url: process.env.VUE_APP_DOCS_URL,
                icon: require('@sharedAssets/docs.svg'),
                target: '_blank'
            }, {
                title: 'Tutorials',
                description: 'And not only! Inspiration from our pioneers, videos from Pycom Hackathons and more!',
                url: process.env.VUE_APP_TUTORIALS_URL,
                icon: require('@sharedAssets/tutorials.svg'),
                target: '_blank'
            }]
        }
    },
    computed: {
        isAuthorized: () => store.isAuthorized,
        user: () => ({ ...store.user })
    }
}
</script>
