<template>
    <v-dialog
        v-model="dialog"
        width="50%"
        max-width="840"
        :persistent="true"
    >
        <v-card class="dialog-bg">
            <v-card-title>
                <h1 class="under-line primary--text">
                    Welcome to Pycom Service Portal
                </h1>
            </v-card-title>

            <v-card-text class="text-center">
                <img
                    class="dialog-text-icon py-2"
                    :src="require('@sharedAssets/new-product.svg')"
                    alt="module menu"
                >
                <div class="py-6">
                    <p>Your Pycom account was successfully created!</p>
                    <p>Only one step left. please check your email ({{ email }})</p>
                    <p>Activate Your Account and get access to all the Pycom services and products!</p>
                </div>
            </v-card-text>

            <v-card-actions class="d-flex flex-column pb-10">
                <v-col cols="12" md="6">
                    <v-btn block color="secondary primary--text" class="btn-dialog mx-auto" @click="resendEmail">
                        Resend Email
                    </v-btn>
                </v-col>
            </v-card-actions>

            <v-alert v-model="alert" class="ma-0" :type="alertType" transition="scale-transition">
                {{ alertMessage }}
            </v-alert>
        </v-card>
    </v-dialog>
</template>

<script>
import { ssoManage, handleNetworkErrors } from '@src/utils/helpers'

export default {
    name: 'VerifyAccount',
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        email: {
            type: String,
            default: 'email'
        }
    },
    data () {
        return {
            alert: false,
            alertType: 'success',
            alertMessage: ''
        }
    },
    methods: {
        notifyMessage: function (status, message, type) {
            if (status === 0) {
                status = ''
                message = 'Server is unreachable, is your network OK ?'
            }

            this.alertMessage = status ? `${status} ${message}` : message
            this.alertType = type || 'success'
            this.alert = true

            setTimeout(() => {
                this.alert = false
            }, 2000)
        },
        resendEmail () {
            ssoManage({
                type: 'POST',
                endpoint: 'api/auth/resend-confirmation-email'
            })
                .then(handleNetworkErrors)
                .then((res) => {
                    if (res.status === 200) {
                        this.notifyMessage(null, 'Successfully sent', 'success')
                    } else {
                        this.notifyMessage(null, 'Server error', 'error')
                    }
                })
                .catch(async (err) => {
                    const error = await err.json()
                    if (error.error === 'EEXIST') {
                        this.notifyMessage(null, 'Invalid token', 'error')
                    }
                })
        }
    }
}
</script>

<style scoped>
    .dialog-bg {
        background-repeat: no-repeat;
        background-color: #fafafa;
    }

    .dialog-text-icon{
        width: 52px;
    }

    .under-line::after {
        background-color: #000;
    }
</style>
