var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticStyle:{"position":"relative"},attrs:{"cols":"12","wrap":""}},[_c('h2',{staticClass:"font-weight-light"},[_vm._v(" Orders ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.orders || [],"single-select":true,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"order_key"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center px-2"},[_c('v-text-field',{staticClass:"mx-2",attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"mx-2"},[_c('v-btn',{staticClass:"success",attrs:{"small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.getUserOrders()}}},[_vm._v(" Refresh ")])],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:_vm.status(item.status),attrs:{"small":""}},[_vm._v(" mdi-circle ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" #"+_vm._s(item.number)+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.currency_symbol)+_vm._s(item.total)+" ")]),_c('small',[_vm._v(" "+_vm._s(_vm._f("numberOfItems")(item.line_items))+" ")])]}},{key:"item.date_paid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDate")(item.date_paid))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('order-details',{attrs:{"order":item}}),_c('order-pdf',{attrs:{"order":item}})],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }