<template>
    <v-row>
        <v-col cols="12" class="my-3" wrap style="position:relative">
            <password />
        </v-col>

        <v-col cols="12" class="my-3" wrap style="position:relative">
            <two-factor-auth />
        </v-col>
    </v-row>
</template>

<script>
import Password from './security/password'
import TwoFactorAuth from './security/twoFactorAuth'

export default {
    name: 'AccountDetails',
    components: {
        Password,
        TwoFactorAuth
    }
}
</script>
