<template>
    <v-container>
        <v-row>
            <v-col cols="12" wrap style="position:relative">
                <h2 class="font-weight-light">
                    Addresses
                </h2>

                <v-data-table
                    dense
                    :search="search"
                    :headers="headers"
                    :items="addresses || []"
                    :single-select="true"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    item-key="_id"
                    class="elevation-1"
                >
                    <template v-slot:[`top`]>
                        <div class="d-flex align-center px-2">
                            <v-text-field
                                v-model="search"
                                label="Search"
                                class="mx-2"
                                append-icon="mdi-magnify"
                            />

                            <div class="mx-2">
                                <manage-addresses :initbsaddresses="BSAddresses" />
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.type`]="{ item }">
                        <span
                            v-for="(val, key) in BSAddresses"
                            :key="key"
                        >
                            <v-chip
                                v-if="item && val && item._id === val._id"
                                small
                                class="mr-1"
                            >
                                {{ key }}
                            </v-chip>
                        </span>
                    </template>

                    <template v-slot:[`item.country`]="{ item }">
                        <flag :iso="item.country" :squared="false" />
                        {{ item.country | toCountry }}
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex justify-end">
                            <manage-addresses type="edit" btn="icon" :initaddress="item" :initbsaddresses="BSAddresses" />
                            <delete-addresses :address="item" />
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import EventBus from '@eventBus'
import { store, mutations } from '@src/store/index'
import { ssoGet, handleNetworkErrors, handleNetworkUnauthorized } from '@src/utils/helpers'
import { toCountry } from '@src/utils/filters'
import ManageAddresses from './addresses/manageAddresses'
import DeleteAddresses from './addresses/deleteAddresses'

export default {
    name: 'Addresses',
    components: {
        ManageAddresses,
        DeleteAddresses
    },
    filters: {
        toCountry
    },
    data: () => ({
        loading: false,
        search: '',
        headers: [
            { text: 'Title', value: 'title' },
            { text: 'Type', value: 'type' },
            { text: 'Email', value: 'email' },
            { text: 'Country', value: 'country' },
            { text: 'City', value: 'city' },
            { text: 'Address', value: 'address1' },
            { text: 'Actions', value: 'actions', align: 'right', sortable: false }
        ]
    }),
    computed: {
        addresses: () => ([...store.addresses]),
        BSAddresses: () => ({ ...store.user.addresses })
    },
    created () {
        this.getUserAddresses()
        EventBus.$on('address:success', () => {
            this.getUserAddresses()
            this.getUserInfo()
        })
    },
    methods: {
        getUserAddresses () {
            this.loading = true
            mutations.set('addresses', [])

            ssoGet('addresses')
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then((data) => mutations.set('addresses', data))
                .then(this.getUserInfo)
                .catch(handleNetworkUnauthorized)
                .finally(() => {
                    this.loading = false
                })
        },
        getUserInfo () {
            ssoGet('oauth/userinfo', true)
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then((user) => mutations.set('user', user))
        }
    }
}
</script>
