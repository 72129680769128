<template>
    <v-container>
        <v-row>
            <v-col cols="12" wrap style="position:relative">
                <h2 class="font-weight-light">
                    Orders
                </h2>

                <v-data-table
                    dense
                    :search="search"
                    :headers="headers"
                    :items="orders || []"
                    :single-select="true"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    item-key="order_key"
                    class="elevation-1"
                >
                    <template v-slot:[`top`]>
                        <div class="d-flex align-center px-2">
                            <v-text-field
                                v-model="search"
                                label="Search"
                                class="mx-2"
                                append-icon="mdi-magnify"
                            />

                            <div class="mx-2">
                                <v-btn
                                    small
                                    class="success"
                                    :disabled="loading"
                                    @click="getUserOrders()"
                                >
                                    Refresh
                                </v-btn>
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <v-icon small :class="status(item.status)">
                            mdi-circle
                        </v-icon>
                        <span class="ml-1">
                            {{ item.status }}
                        </span>
                    </template>

                    <template v-slot:[`item.number`]="{ item }">
                        #{{ item.number }}
                    </template>

                    <template v-slot:[`item.total`]="{ item }">
                        <span>
                            {{ item.currency_symbol }}{{ item.total }}
                        </span>
                        <small>
                            {{ item.line_items | numberOfItems }}
                        </small>
                    </template>

                    <template v-slot:[`item.date_paid`]="{ item }">
                        {{ item.date_paid | localDate }}
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex justify-end">
                            <order-details :order="item" />
                            <order-pdf :order="item" />
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { store, mutations } from '@src/store/index'
import { ssoGet, handleNetworkErrors, handleNetworkUnauthorized } from '@src/utils/helpers'
import { localDate, numberOfItems } from '@src/utils/filters'
import OrderPdf from './orders/orderPdf'
import OrderDetails from './orders/orderDetails'

export default {
    name: 'Invoices',
    components: {
        OrderPdf,
        OrderDetails
    },
    filters: {
        numberOfItems,
        localDate
    },
    data: () => ({
        loading: false,
        search: '',
        headers: [
            { text: 'Order', value: 'number' },
            { text: 'Status', value: 'status' },
            { text: 'Date', value: 'date_paid' },
            { text: 'Total', value: 'total' },
            { text: 'Actions', value: 'actions', align: 'right', sortable: false }
        ]
    }),
    computed: {
        orders: () => ([...store.orders])
    },
    created () {
        this.getUserOrders()
    },
    methods: {
        getUserOrders () {
            this.loading = true

            ssoGet('orders')
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then((data) => mutations.set('orders', data))
                .catch(handleNetworkUnauthorized)
                .finally(() => {
                    this.loading = false
                })
        },
        status (v) {
            switch (v) {
                case 'completed':
                    return 'teal--text text--accent-4'
                case 'processing':
                    return 'yellow--text yellow--accent-4'
                case 'refunded':
                    return 'blue--text blue--accent-4'
                default:
                    return 'black--text'
            }
        }
    }
}
</script>
