<template>
    <v-container>
        <v-row>
            <v-col cols="12" wrap>
                <h2 class="font-weight-light">
                    Sessions
                </h2>

                <v-data-table
                    v-model="selectedTokens"
                    dense
                    :search="search"
                    :headers="headers"
                    :items="reversedTokens"
                    :footer-props="footer"
                    show-select
                    item-key="_id"
                    class="elevation-1"
                >
                    <template v-slot:[`top`]>
                        <div class="d-flex align-center px-2">
                            <v-text-field
                                v-model="search"
                                label="Search"
                                class="mx-2"
                                append-icon="mdi-magnify"
                            />
                            <v-btn
                                id="deleteSessions-btn"
                                small
                                color="error"
                                :disabled="!hasOneSelected"
                                @click="removeSelectedTokens"
                            >
                                delete sessions
                            </v-btn>
                        </div>
                    </template>

                    <template v-slot:[`item.env.ip`]="{ item }">
                        <small class="mr-2">
                            <flag
                                v-if="item.env.ipLookup"
                                :iso="item.env.ipLookup.country | lowercase"
                                :squared="false"
                            />
                        </small>
                        <span>
                            {{ item.env.ip }}
                        </span>
                    </template>

                    <template v-slot:[`item.serviceName`]="{ item }">
                        <v-icon small :style="{ color: item.color }">
                            mdi-circle
                        </v-icon>
                        <span v-if="item.current">
                            current
                        </span>
                        <span v-if="!item.current">
                            {{ item.serviceName || item.clientId }}
                        </span>
                    </template>

                    <template v-slot:[`item.updatedAt`]="{ item }">
                        {{ item.updatedAt | localDate }}
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small color="error" @click="removeTokenById(item._id)">
                            mdi-trash-can-outline
                        </v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" wrap>
                <h2 class="font-weight-light error--text">
                    Delete Account
                </h2>

                <p class="caption">
                    Your account will be immediately deactivated. Please be certain.
                </p>

                <v-btn
                    id="deleteAccount-btn"
                    small
                    outlined
                    color="error"
                    @click.stop="dialogDeleteAccount = true"
                >
                    Delete my account
                </v-btn>
            </v-col>
        </v-row>

        <DeleteAccountForm :show="dialogDeleteAccount" @update-show="update" />
    </v-container>
</template>

<script>
import EventBus from '@eventBus'
import { store } from '@src/store/index'
import { localDate, lowercase } from '@src/utils/filters'
import { ssoGet, ssoManage, handleNetworkErrors, handleNetworkUnauthorized } from '@src/utils/helpers'
import DeleteAccountForm from './DeleteAccountForm'

export default {
    name: 'Advanced',
    components: {
        DeleteAccountForm
    },
    filters: {
        localDate,
        lowercase
    },
    data: () => ({
        search: '',
        headers: [
            { text: 'IP', value: 'env.ip' },
            { text: 'ENV', value: 'env.agent' },
            { text: 'Service', value: 'serviceName' },
            { text: 'Updated', value: 'updatedAt' },
            { text: 'Actions', value: 'actions', align: 'right', sortable: false }
        ],
        footer: {
            rowsPerPage: 50,
            itemsPerPageOptions: [25, 50, 100, -1]
        },
        tokens: [],
        selectedTokens: [],
        dialogDeleteAccount: false
    }),
    computed: {
        user: () => ({ ...store.user }),
        reversedTokens () {
            return this.tokens.slice(0).reverse()
        },
        hasOneSelected () {
            return this.selectedTokens.length > 0
        }
    },
    created () {
        this.getUserTokens()
    },
    methods: {
        update (show) {
            this.dialogDeleteAccount = show
        },
        getUserTokens () {
            ssoGet('oauth/userinfo/sessions')
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then((data) => {
                    this.tokens = data.map((d) => ({
                        ...d,
                        selected: false,
                        color: d.parentSessionID ? this.toRGB(d.parentSessionID) : this.toRGB(d._id)
                    }))
                })
                .catch(handleNetworkUnauthorized)
        },
        removeTokenById (id) {
            this.removeTokens([id])
        },
        removeSelectedTokens () {
            this.removeTokens(this.selectedTokens)
        },
        removeTokens (data) {
            ssoManage({
                type: 'DELETE',
                endpoint: 'oauth/userinfo/sessions',
                payload: data
            })
                .then(handleNetworkErrors)
                .then(() => {
                    EventBus.$emit('snackbar:show', {
                        type: 'success',
                        message: 'Your session has been deleted'
                    })
                    this.getUserTokens()
                })
                .catch(() => {
                    EventBus.$emit('snackbar:show', {
                        type: 'error',
                        message: 'Your session cannot be deleted'
                    })
                })
        },
        toRGB: (value) => {
            let hash = 0
            if (value.length === 0) return hash
            for (let i = 0; i < value.length; i++) {
                hash = value.charCodeAt(i) + ((hash << 5) - hash)
                hash = hash & hash
            }
            const rgb = [0, 0, 0]
            for (let i = 0; i < 3; i++) {
                rgb[i] = (hash >> (i * 8)) & 255
            }
            return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
        }
    }
}
</script>

<style scoped>
.v-list-item__subtitle {
    font-size: 0.75em;
}

.v-list-item__subtitle .v-icon {
    font-size: 1em;
}

.v-list-item__action {
    align-self: flex-start !important;
}
</style>
