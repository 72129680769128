<template>
    <v-container>
        <h1 class="page-title">
            Account Settings
        </h1>

        <v-tabs v-model="tab" light show-arrows>
            <v-tabs-slider />

            <v-tab
                v-for="setting in settings"
                :key="setting.link"
                :to="setting.link"
                :disabled="setting.soon"
                exact
            >
                {{ setting.title }}
                <small v-if="setting.soon">
                    (soon)
                </small>
            </v-tab>
        </v-tabs>

        <v-row no-gutters>
            <router-view />
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Account',
    data: () => ({
        tab: null,
        settings: [{
            title: 'Personal Details',
            link: '/account'
        }, {
            title: 'Addresses',
            link: '/account/addresses'
        }, {
            title: 'Orders',
            link: '/account/orders'
        }, {
            title: 'Security',
            link: '/account/security'
        }, {
            title: 'Tokens',
            link: '/account/tokens',
            soon: !(process.env.VUE_APP_ENABLE_PAT === 'true')
        }, {
            title: 'Advanced',
            link: '/account/advanced'
        }]
    })
}
</script>
