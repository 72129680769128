import Vue from 'vue'
import debug from 'debug'
const log = debug('app:store')

export const store = Vue.observable({
    isAuthorized: false,
    isAdmin: false,
    user: null,
    addresses: null,
    tokens: [],
    orders: []
})

export const mutations = {
    set (type, data) {
        if (type in store) {
            log(`[STORE] ${type} updated:`, data)
            store[type] = data
        } else {
            log(`[STORE] ${type} does not exist`)
        }
    }
}
