<template>
    <div>
        <div v-if="isLoading" class="over">
            <loader />
        </div>

        <h2 class="font-weight-light">
            Password
        </h2>

        <v-form
            id="account-password"
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
            @submit.prevent="updatePassword"
        >
            <v-row>
                <v-col sm="6" cols="12">
                    <v-text-field
                        v-model="currentPassword"
                        label="Current password"
                        :rules="requiredRule"
                        :type="showCurrentPassword ? 'text' : 'password'"
                        :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showCurrentPassword = !showCurrentPassword"
                    />
                </v-col>

                <v-col sm="6" cols="12">
                    <v-text-field
                        v-model="newPassword"
                        label="New password"
                        :rules="passwordRules"
                        :type="showNewPassword ? 'text' : 'password'"
                        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showNewPassword = !showNewPassword"
                    />
                </v-col>

                <v-col cols="12">
                    <v-btn
                        :disabled="!valid || isLoading || !currentPassword.length || !newPassword.length"
                        type="submit"
                        color="success"
                    >
                        Change
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import EventBus from '@eventBus'
import { ssoManage, handleNetworkErrors, handleNetworkUnauthorized } from '@src/utils/helpers'
import Loader from '../../shared/Loader'

export default {
    name: 'Password',
    components: {
        Loader
    },
    data () {
        return {
            lazy: true,
            valid: false,
            isLoading: false,
            showCurrentPassword: false,
            showNewPassword: false,
            currentPassword: '',
            newPassword: '',
            requiredRule: [
                (v) => !!v || 'Current password is required'
            ],
            passwordRules: [
                (v) => v.length >= 8 || 'Must be longer than 8 chars',
                (v) => /[A-Z]+/.test(v) || 'Must contain at least one uppercase letter',
                (v) => /[0-9]+/.test(v) || 'Must contain at least one number',
                (v) => /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]+/.test(v) || 'Must contain at least one special char'
            ]
        }
    },
    methods: {
        updatePassword (e) {
            this.isLoading = true

            const payload = {
                currentPassword: this.currentPassword,
                newPassword: this.newPassword
            }

            ssoManage({
                payload,
                type: 'PUT',
                endpoint: 'oauth/userinfo/password'
            })
                .then(handleNetworkErrors)
                .then(this.successHandler)
                .catch((err) => {
                    handleNetworkUnauthorized(err)
                    this.errorHandler()
                })
                .finally(this.resetState)
        },
        successHandler () {
            EventBus.$emit('snackbar:show', {
                type: 'success',
                message: 'Your password has been updated !'
            })
        },
        errorHandler () {
            EventBus.$emit('snackbar:show', {
                type: 'error',
                message: 'Your password is invalid'
            })
        },
        resetState () {
            this.isLoading = false
            this.currentPassword = ''
            this.newPassword = ''
            document.activeElement.blur()
            this.$refs.form.resetValidation()
        }
    }
}
</script>
