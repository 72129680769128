<template>
    <div class="address-wrapper my-2">
        <v-list dense>
            <v-list-item>
                {{ address.first_name }} {{ address.last_name }}
            </v-list-item>

            <v-list-item>
                {{ address.address_1 }}
            </v-list-item>

            <v-list-item v-if="address.address_2 ">
                {{ address.address_2 }}
            </v-list-item>

            <v-list-item>
                {{ address.postcode }} {{ address.city }}
            </v-list-item>

            <v-list-item>
                <flag :iso="address.country" :squared="false" />
                <span class="ml-1">
                    {{ address.country | toCountry }}
                </span>
            </v-list-item>

            <v-list-item v-if="address.phone">
                {{ address.phone }}
            </v-list-item>

            <v-list-item v-if="address.email">
                {{ address.email }}
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import { toCountry } from '@src/utils/filters'

export default {
    name: 'OrderAddress',
    filters: {
        toCountry
    },
    props: {
        address: {
            type: Object,
            default: () => ({})
        }
    }

}
</script>

<style scoped>
.address-wrapper {
    border: solid 1px lightgray;
}
.v-list--dense .v-list-item, .v-list-item--dense {
    min-height: 30px !important;
}
</style>
