import lookup from 'country-code-lookup'

/**
 * Returns shipping method
 * @param {Object} v  woocommerce order
 */
export function shippingMethod (v) {
    return v.length ? v[0].method_title : 'unknown'
}

/**
 * Transform date to local date
 * @param {bool} b  replace ',' by 'at'
 */
export function localDate (v, b = false) {
    const local = v ? new Date(v).toLocaleString().toLowerCase() : false
    return local ? (b ? local.replace(',', ' at') : local) : ''
}

/**
 * Returns the number of items
 * @param {Object} v  woocommerce order line_items
 */
export function numberOfItems (v) {
    const items = v.reduce((acc, cur) => {
        acc = acc + cur.quantity
        return acc
    }, 0)
    return items > 1 ? `for ${items} items` : `for ${items} item`
}

/**
 * Lookup country
 * @param {string} v  FR, EN, ES etc...
 */
export function toCountry (v) {
    return v ? lookup.byIso(v).country : ''
}

/**
 * Returns a string to lowercase
 * @param {string} v
 */
export function lowercase (v) {
    return v ? v.toLowerCase() : ''
}
