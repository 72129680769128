<template>
    <div>
        <v-alert
            icon="mdi-shield-lock-outline"
            text
            dense
            type="success"
            color="green"
            border="left"
            class="my-4"
        >
            <div class="mb-1">
                Two factor authentication is enabled !
            </div>
            <small>
                2FA adds an additional layer of security to your account by requiring more than just a password to log in.
            </small>
        </v-alert>

        <v-dialog
            v-model="dialog"
            width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    outlined
                    depressed
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                >
                    Disable two-factor authentication
                </v-btn>
            </template>

            <v-card>
                <v-form
                    ref="form"
                    v-model="valid"
                    @submit.prevent="disableTwoFactorAuth"
                >
                    <v-card-title class="headline primary white--text">
                        <span>Disable 2FA</span>
                        <v-spacer />
                        <v-btn icon dark @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-alert
                                        icon="mdi-shield-off-outline"
                                        text
                                        dense
                                        type="warning"
                                        border="left"
                                    >
                                        You are about to disable two-factor authentication. This will invalidate your current two-factor devices and recovery codes.
                                    </v-alert>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                        v-model="password"
                                        label="Confirm your password"
                                        :type="showPwd ? 'text' : 'password'"
                                        :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="passwordRules"
                                        dense
                                        required
                                        @click:append="showPwd = !showPwd"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-divider />

                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            outlined
                            type="submit"
                            color="error"
                            :disabled="isLoading || !valid"
                        >
                            <span v-if="!isLoading">
                                I understand, disable 2FA
                            </span>
                            <v-icon v-if="isLoading">
                                mdi-loading mdi-spin
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import EventBus from '@eventBus'
import { ssoManage, handleNetworkErrors } from '@src/utils/helpers'

export default {
    name: 'DisableTwoFactorAuth',
    data: () => ({
        dialog: false,
        valid: false,
        isLoading: false,
        showPwd: false,
        password: '',
        passwordRules: [
            (v) => !!v || 'Password is required'
        ]
    }),
    methods: {
        disableTwoFactorAuth () {
            this.isLoading = true

            ssoManage({
                type: 'DELETE',
                endpoint: 'two-factor',
                payload: { password: this.password }
            })
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then(this.successHandler)
                .catch(this.resetState)
        },
        resetState () {
            this.dialog = false
            this.isLoading = false
        },
        successHandler (data) {
            EventBus.$emit('twoFactorAuth:success')
            EventBus.$emit('snackbar:show', {
                type: 'success',
                message: 'The 2FA code has been deleted !'
            })
            this.resetState()
        }
    }
}
</script>
