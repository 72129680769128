<template>
    <v-row>
        <v-col cols="12" wrap style="position:relative">
            <div v-if="isLoading" class="over">
                <loader />
            </div>

            <v-form
                id="account-details"
                ref="form"
                v-model="valid"
                :lazy-validation="lazy"
                @submit.prevent="updateUser"
            >
                <v-row>
                    <v-col sm="6" cols="12">
                        <v-text-field
                            v-model="user.firstname"
                            label="Firstname"
                            :rules="requiredRule"
                            required
                            @input="onChange"
                        />
                    </v-col>

                    <v-col sm="6" cols="12">
                        <v-text-field
                            v-model="user.lastname"
                            label="Lastname"
                            :rules="requiredRule"
                            required
                            @input="onChange"
                        />
                    </v-col>

                    <v-col cols="12">
                        <v-text-field
                            v-model="user.email"
                            label="Email"
                            disabled
                        />
                    </v-col>

                    <v-col cols="12">
                        <v-btn
                            :disabled="!valid || isLoading || !hasChanged"
                            type="submit"
                            color="success"
                        >
                            Change
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
import EventBus from '@eventBus'
import { store, mutations } from '@src/store/index'
import { ssoManage, handleNetworkErrors, handleNetworkUnauthorized } from '@src/utils/helpers'
import Loader from '../shared/Loader'

export default {
    name: 'AccountDetails',
    components: {
        Loader
    },
    data () {
        return {
            lazy: true,
            valid: false,
            isLoading: false,
            hasChanged: false,
            requiredRule: [
                (v) => !!v || 'Field is required'
            ]
        }
    },
    computed: {
        user: () => ({ ...store.user })
    },
    methods: {
        updateUser (e) {
            this.isLoading = true

            const payload = {
                firstname: this.user.firstname,
                lastname: this.user.lastname
            }

            ssoManage({
                payload,
                type: 'PUT',
                endpoint: 'oauth/userinfo'
            })
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then(this.successHandler)
                .catch(handleNetworkUnauthorized)
                .finally(this.resetState)
        },
        successHandler (data) {
            mutations.set('user', data)
            EventBus.$emit('snackbar:show', {
                type: 'success',
                message: 'Your details have been updated !'
            })
        },
        resetState () {
            this.isLoading = false
            this.hasChanged = false
            document.activeElement.blur()
            this.$refs.form.resetValidation()
        },
        onChange () {
            const sUser = store.user
            const lUser = this.user
            this.hasChanged = sUser.firstname !== lUser.firstname || sUser.lastname !== lUser.lastname
        }
    }
}
</script>
