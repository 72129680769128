<template>
    <v-app-bar app flat dark color="black" height="87">
        <v-container fluid>
            <v-layout mx-0 mx-sm-5 justify-space-between>
                <router-link to="/" class="layout align-center flex-grow-0">
                    <img id="logo" class="logo" :src="require('@sharedAssets/pycom.svg')" alt="Pycom">
                </router-link>

                <v-spacer />

                <v-menu left offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-layout id="subNav" class="flex-grow-0 px-2" v-bind="attrs" v-on="on">
                            <img :src="require('@sharedAssets/avatar.svg')" alt="Pycom account">
                            <v-icon small>
                                mdi-chevron-down
                            </v-icon>
                        </v-layout>
                    </template>

                    <v-list id="subNav-list" dense class="py-0">
                        <v-subheader class="px-4">
                            {{ userEmail }}
                        </v-subheader>

                        <v-divider />

                        <v-list-item-group v-model="item">
                            <v-list-item
                                v-for="item in navItems"
                                :key="item.title"
                                :value="item.link"
                                :to="item.to"
                                :href="item.href"
                            >
                                <v-list-item-icon class="mr-3">
                                    <v-icon v-text="item.icon" />
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title v-text="item.title" />
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click.prevent="logout">
                                <v-list-item-icon class="mr-3">
                                    <v-icon>mdi-logout</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>Logout</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </v-layout>
        </v-container>
    </v-app-bar>
</template>

<script>
import { ssoRedirect } from '@src/utils/helpers'
import { store } from '@src/store/index'

export default {
    name: 'HeaderSection',
    props: {
        userEmail: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        item: null
    }),
    computed: {
        isAdmin: () => ({ ...store.isAdmin }),
        navItems: function () {
            const items = [{
                icon: 'mdi-home',
                title: 'Home',
                to: '/'
            }, {
                icon: 'mdi-account-cog',
                title: 'Settings',
                to: '/account'
            }]

            const adminItems = [{
                icon: 'mdi-fire',
                title: 'Admin',
                href: process.env.VUE_APP_ADMIN_URL
            }]

            return store.isAdmin ? [...items, ...adminItems] : items
        }
    },
    methods: {
        logout () {
            ssoRedirect('logout')
        }
    }
}
</script>

<style scoped>
    #subNav {
        opacity: 1;
        transition: ease-out opacity 150ms;
    }

    #subNav:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .user-details {
        justify-content: flex-end;
        align-items: center;
    }

    .user-email {
        color: #FFF;
        line-height: 3.2em;
    }

    .logo {
        filter: invert(1);
        width: auto;
        height: 45px;
    }

    button.v-btn.logout-btn {
        height: 53px;
    }

    .logout-btn-img {
        width: 25px;
    }
</style>
