<template>
    <v-btn
        icon
        :href="`${shopUrl}/invoice/${order.id}`"
        target="_blank"
    >
        <v-icon
            small
            class="ml-1"
        >
            mdi-file-pdf
        </v-icon>
    </v-btn>
</template>

<script>
export default {
    name: 'OrderPdf',
    props: {
        order: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            shopUrl: process.env.VUE_APP_SHOP
        }
    }
}
</script>
