import debug from 'debug'
import EventBus from '@eventBus'
const log = debug('app:network')

/**
 * Handle network error
 */
export function handleNetworkErrors (res) {
    const { ok, status } = res
    log('handleNetworkErrors:', res)

    if (!ok) {
        // Server error
        if (/^5/.test(status.toString())) {
            EventBus.$emit('snackbar:show', {
                type: 'error',
                message: 'Server error'
            })

            // Default error
        } else {
            EventBus.$emit('snackbar:show', {
                type: 'error',
                message: res.statusText || 'Error'
            })
        }

        throw res
    }

    return res
}

/**
 * Handle Unauthorized Network response
 */
export function handleNetworkUnauthorized (res) {
    log('handleNetworkUnauthorized:', res)
    if (res.status === 401) ssoRedirect('logout')
}

/**
 * R : Read
 * @param {string} endpoint
 */
export async function ssoGet (endpoint, all = false) {
    const full = all ? '?full=true' : ''
    const url = `${process.env.VUE_APP_SSO_URL}/${endpoint}${full}`
    const opt = {
        method: 'GET',
        credentials: 'include'
    }
    return fetch(url, opt)
}

/**
 * CUD : Create, Update, Delete
 * @param {string} id
 * @param {string} type request type POST, PUT...
 * @param {string} payload
 * @param {string} endpoint
 */
export async function ssoManage ({ endpoint, type, id, payload = {} }) {
    const url = id && type !== 'POST'
        ? `${process.env.VUE_APP_SSO_URL}/${endpoint}/${id}`
        : `${process.env.VUE_APP_SSO_URL}/${endpoint}`

    const opt = { method: type, credentials: 'include' }

    if (type !== 'GET') {
        opt.body = JSON.stringify(payload)
        opt.headers = { 'Content-type': 'application/json; charset=UTF-8' }
    }

    return fetch(url, opt)
}

/**
 * SSO Redirection
 * @param {string} endpoint
 */
export function ssoRedirect (endpoint) {
    window.location = `${process.env.VUE_APP_SSO_URL}/${endpoint}`
}
