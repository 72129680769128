import Vue from 'vue'
import Vuetify from 'vuetify'

import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#000000',
                secondary: '#FFFFFF',
                accent: '#898989',
                error: '#FC1247',
                info: '#17FFBD',
                success: '#00cc96',
                warning: '#FF450A'
            },
            dark: {
                primary: '#000000',
                secondary: '#FFFFFF',
                accent: '#898989',
                error: '#FC1247',
                info: '#17FFBD',
                success: '#00cc96',
                warning: '#FF450A'
            }
        }
    }
})
