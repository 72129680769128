<template>
    <v-snackbar
        v-if="isAuthorized"
        v-model="snackbar"
        app
        :top="true"
        :right="true"
        :timeout="timeout"
        :color="color"
    >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn dark fab text small v-bind="attrs" @click="resetSnackBar">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import EventBus from '@eventBus'
import { store } from '@src/store/index'

export default {
    name: 'SnackBar',
    data: () => ({
        snackbar: false,
        message: null,
        color: null,
        timeout: -1
    }),
    computed: {
        isAuthorized: () => store.isAuthorized
    },
    created () {
        // Show
        EventBus.$on('snackbar:show', (payload) => {
            this.resetSnackBar()
            setTimeout(() => this.displaySnackbar(payload), 1)
        })
        // Hide
        EventBus.$on('snackbar:hide', this.resetSnackBar)
    },
    methods: {
        displaySnackbar ({ type, message }) {
            switch (type) {
                case 'error':
                    this.color = 'error'
                    break
                case 'success':
                    this.color = 'success'
                    this.timeout = 4000
                    break
                default:
                    this.color = 'accent'
                    break
            }

            this.snackbar = true
            this.message = message
        },
        resetSnackBar () {
            this.snackbar = false
            this.message = null
            this.color = null
            this.timeout = -1
        }
    }
}
</script>
