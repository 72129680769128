<template>
    <v-dialog
        v-model="dialog"
        width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                color="error"
                class="ml-1"
                v-bind="attrs"
                v-on="on"
            >
                mdi-trash-can-outline
            </v-icon>
        </template>

        <v-card>
            <v-form
                ref="form"
                @submit.prevent="deleteKey"
            >
                <v-card-title class="headline primary white--text">
                    <span>Delete Token</span>
                    <v-spacer />
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-alert
                                    icon="mdi-shield-lock-outline"
                                    text
                                    dense
                                    type="warning"
                                    border="left"
                                >
                                    Any applications or scripts using this Token will no longer be able to access the Pycom API. You cannot undo this action.
                                </v-alert>
                            </v-col>
                            <v-col cols="12">
                                <p>Please type the token note: <b>{{ token.note }}</b> if you are sure to delete it.</p>

                                <v-text-field
                                    value=""
                                    name="deleteNote"
                                    label="Type the token note"
                                    :rules="deleteRule"
                                    required
                                    @input="onChange"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        outlined
                        :disabled="isLoading || !valid"
                        type="submit"
                        color="error"
                    >
                        <span v-if="!isLoading">
                            I understand, delete this token
                        </span>
                        <v-icon v-if="isLoading">
                            mdi-loading mdi-spin
                        </v-icon>
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import EventBus from '@eventBus'
import { ssoManage, handleNetworkErrors } from '@src/utils/helpers'

export default {
    name: 'DeleteToken',
    props: {
        token: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        dialog: false,
        valid: false,
        isLoading: false,
        deleteRule: [
            (v) => !!v || 'This field is required'
        ]
    }),
    methods: {
        deleteKey () {
            this.isLoading = true

            ssoManage({
                type: 'DELETE',
                id: this.token._id,
                endpoint: 'personal-tokens'
            })
                .then(handleNetworkErrors)
                .then((res) => res.json())
                .then(this.successHandler)
                .catch(this.resetState)
        },
        resetState () {
            this.dialog = false
            this.isLoading = false
        },
        successHandler (data) {
            EventBus.$emit('token:success')
            EventBus.$emit('snackbar:show', {
                type: 'success',
                message: 'The Token has been deleted !'
            })
            this.resetState()
        },
        onChange (v) {
            this.valid = v === this.token.note
        }
    }
}
</script>
